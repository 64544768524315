<template>
  <div>
    <vs-button
      class="px-0 py-0 m-0"
      radius
      :disabled="!$atividades.permissoes.includes(2)"
      style="color: black !important"
      size="medium"
      type="relief"
      @click="errors.clear(), (addContatoModal = true)"
      icon="contacts"
    ></vs-button>
    <vs-popup
      :title="'Contatos : ' + endereco.nome"
      :active.sync="addContatoModal"
    >
      <div class="sizeModal w-full">
        <VuePerfectScrollbar class="scroll-add-contato-endereco" :settings="settings">
          <div id="contatoEndHead" class="vs-con-loading__container px-2">
            <div class="vx-row" v-if="contatos.length > 0">
              <div class="w-full px-3">
                <vs-list>
                  <span :key="indextr" v-for="(tr, indextr) in contatos">
                    <vx-card
                      class="card-lev overflow-hidden my-2"
                      :card-background="tr.lixeira == 1 ? 'danger' : ''"
                    >
                      <template slot="no-body">
                        <div class="item-details px-2">
                          <div class="my-2">
                            <vs-col
                              vs-w="0.5"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              class=""
                            >
                              <span v-if="tr.tipo == 1">
                                <vs-icon
                                  icon="alternate_email"
                                  size="22px"
                                  color="dark"
                                ></vs-icon>
                              </span>
                              <span v-else>
                                <vs-icon
                                  icon="phone"
                                  size="22px"
                                  color="dark"
                                ></vs-icon>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="10"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              class=""
                              :style="
                                tr.lixeira == 1
                                  ? 'color: white'
                                  : 'color: black'
                              "
                            >
                              <span
                                style="font-size: 14x;max-width: 50% !important;overflow: hidden;text-align:flex-start;text-overflow: ellipsis;white-space: nowrap;"
                                >{{ tr.nome }}</span
                              >
                              <span
                                style="font-size: 14px;max-width: 100% !important;overflow: hidden;text-align:flex-start;text-overflow: ellipsis;white-space: nowrap;"
                              >
                                &nbsp;- <b>{{ tr.contato }}</b></span
                              >
                            </vs-col>

                            <vs-col
                              vs-w="1.5"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <div
                                @click="atualizar(tr, 1)"
                                v-if="tr.lixeira == 0"
                              >
                                <vs-icon
                                  style="cursor: pointer"
                                  icon="highlight_off"
                                  size="25px"
                                  color="danger"
                                ></vs-icon>
                              </div>
                              <div @click="atualizar(tr, 0)" v-else>
                                <vs-icon
                                  style="cursor: pointer"
                                  icon="undo"
                                  size="25px"
                                  color="warning"
                                ></vs-icon>
                              </div>
                            </vs-col>
                          </div>
                        </div>
                      </template>
                    </vx-card>
                  </span>
                </vs-list>
              </div>
            </div>
          </div>

          <vs-row vs-type="flex" vs-align="center" class="mb-3">
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class=""
            >
              <div style="display:inline-block" class=" w-full">
                <vs-input
                  label="Nome"
                  name="nome"
                  v-validate="'required'"
                  v-model="contato.nome"
                  class="inputx w-full px-1"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('nome')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class=""
            >
              <b-form-group>
                <b-form-radio-group
                  id="tipo"
                  v-model="contato.tipo"
                  :options="tipoContato"
                  size="lg"
                  @change="reRender += 1"
                  buttons
                  button-variant=""
                  name="tipo"
                  class="pt-10"
                ></b-form-radio-group>
              </b-form-group>
            </vs-col>
            <vs-col
              vs-w="8"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class=" "
            >
              <div style="display:inline-block" class=" w-full">
                <vs-input
                  label="Contato"
                  name="contato"
                  :disabled="contato.tipo ? false : true"
                  v-model="contato.contato"
                  :key="reRender"
                  type="tel"
                  v-validate="'required'"
                  v-mask="contato.tipo == 2 ? '(##)#####-####' : mascara"
                  class="inputx w-full  px-1"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('contato')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col
              vs-w="4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="mt-6"
            >
              <SuccessButton
                @func="submitForm"
                :disabled="blockButton"
              ></SuccessButton>
            </vs-col>
          </vs-row>
        </VuePerfectScrollbar>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    endereco: {}
  },
  data() {
    return {
      addContatoModal: false,
      contato: {
        contato: ""
      },
      contatos: {},
      tipoContato: [
        { value: 1, text: "E-MAIL" },
        { value: 2, text: "TELEFONE" }
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      },
      blockButton: false,
      mascara: "X".repeat(50),
      reRender: 0
    };
  },
  methods: {
    async addcontato() {
      await this.$vs.loading({ container: "#contatoEndHead", scale: 0.6 });
      try {
        await this.$http.post("contatosEndereco", [
          this.contato,
          { id_endereco: this.endereco.id }
        ]);
        await this.getContatosEndereco();
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close("#contatoEndHead > .con-vs-loading");
      } finally {
        this.contato = {};
        this.blockButton = false;
        this.reRender += 1;
        await this.$vs.loading.close("#contatoEndHead > .con-vs-loading");
      }
    },
    async atualizar(contato, status) {
      await this.$vs.loading({ container: "#contatoEndHead", scale: 0.6 });
      try {
        await this.$http.put("mudarStatusContatoEnderecoCliente/" + contato.id, {
          lixeira: status
        });
        await this.getContatosEndereco();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#contatoEndHead > .con-vs-loading");
      }
    },
    async getContatosEndereco() {
      await this.$vs.loading({ container: "#contatoEndHead", scale: 0.6 });
      try {
        this.contatos = await this.$http.get(
          "contatosEnderecoEndereco/" + this.endereco.id
        );
        await this.$vs.loading.close("#contatoEndHead > .con-vs-loading");
      } catch (err) {
        await this.$vs.loading.close("#contatoEndHead > .con-vs-loading");
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async submitForm() {
      this.blockButton = true;
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.addcontato();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
            this.blockButton = false;
          }
        });
      });
    }
  },
  async mounted() {
    await this.getContatosEndereco();
  },
  components: { ...components }
};
</script>

<style lang="scss" scoped>
.card-lev {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-add-contato-endereco {
  max-height: 55vh !important;
}
.sizeModal {
  height: 60vh !important;
}
</style>
