<template>
  <div>
    <vs-button
      class="px-0 py-0 m-0"
      size="medium"
      radius
      style="color: black !important"
      :disabled="!$atividades.permissoes.includes(2)"
      color="primary"
      type="relief"
      @click="errors.clear(), (levantamentoNovoModal = true)"
      icon="verified_user"
    ></vs-button>
    <vs-popup
      class=""
      :title="'Levantamentos: ' + endereco.nome"
      :active.sync="levantamentoNovoModal"
    >
      <div class="sizeModal w-full">
        <VuePerfectScrollbar
          class="scroll-levantamento-novo-modal"
          :settings="settings"
        >
          <div id="container" class="vs-con-loading__container px-2">
            <div class="vx-row" v-if="levantamentos.length > 0">
              <div class="w-full px-3">
                <vs-list>
                  <span :key="indextr" v-for="(tr, indextr) in levantamentos">
                    <vx-card class="card-lev overflow-hidden my-2">
                      <template slot="no-body">
                        <div class="item-details px-2">
                          <div class="my-2">
                            <vs-col
                              vs-w="0.8"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              class=""
                            >
                              <span>
                                <vs-icon
                                  icon="alarm"
                                  size="25px"
                                  color="success"
                                ></vs-icon>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="9.2"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              class=""
                            >
                              <span
                                style="
                                  font-size: 16px;
                                  max-width: 49% !important;
                                  overflow: hidden;
                                  text-align: flex-start;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                "
                                >Data:
                                <b>{{ tr.data_competencia }}&nbsp;</b></span
                              >
                              <span
                                class="mx-1"
                                v-if="tr.hora_marcada"
                                style="
                                  font-size: 16px;
                                  max-width: 49% !important;
                                  overflow: hidden;
                                  text-align: flex-start;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                "
                              >
                                &nbsp;às
                                <b>{{ tr.hora_marcada }}</b></span
                              >
                              <span
                                v-if="tr.id_turno"
                                class="mx-1"
                                style="
                                  font-size: 16px;
                                  max-width: 49% !important;
                                  overflow: hidden;
                                  text-align: flex-start;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                "
                                >-
                                <b>{{ turnos[tr.id_turno - 1].nome }}</b></span
                              >
                            </vs-col>
                            <vs-col
                              vs-w="2"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="center"
                              v-if="tr.executado != 2"
                            >
                              <vs-button
                                icon="remove_red_eye"
                                :disabled="!(tr.executado == 1)"
                                color="success"
                                size="small"
                                type="filled"
                                @click="redirectBlank(tr)"
                              ></vs-button>
                              <!-- </a> -->
                            </vs-col>
                            <vs-col
                              vs-w="2"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="center"
                              v-else
                            >
                              <LevNaoExec
                                @close="levantamentoNovoModal = false"
                                :lev="tr"
                              />
                            </vs-col>
                          </div>
                        </div>
                      </template>
                    </vx-card>
                  </span>
                </vs-list>
              </div>
            </div>
          </div>
          <vs-row vs-type="flex" vs-align="center" class="mb-3">
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class=""
            >
              <div style="display: inline-block" class="w-full">
                <vs-input
                  label="Data competência"
                  name="data_competencia"
                  v-model="levantamento.data_competencia"
                  v-validate="'required'"
                  type="date"
                  class="inputx w-full px-1"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('data_competencia')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class=""
            >
              <div style="display: inline-block; margin-right: 20px">
                <label style="color: black; font-size: 13px">Turno</label><br />
                <el-select
                  filterable
                  clearable
                  class="select-info"
                  placeholder="Selecione..."
                  name=""
                  v-model="levantamento.id_turno"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="turno in turnos"
                    class="select-info"
                    :value="turno.id"
                    :label="turno.nome"
                    :key="turno.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="mt-2"
            >
              <vs-input
                label="Hora marcada"
                v-mask="'##:00'"
                name="hora_marcada"
                v-model="levantamento.hora_marcada"
                type="datetime"
                class="inputx w-full px-1 pb-1"
              />
            </vs-col>
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="mt-6"
            >
              <SuccessButton
                :disabled="blockButton || blockLenvNovoModal"
                @func="!(blockButton && blockLenvNovoModal) ? submitForm() : ''"
              ></SuccessButton>
            </vs-col>
          </vs-row>
        </VuePerfectScrollbar>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import LevNaoExec from "../../agenda-servicos/lev/components/levNaoExec"
export default {
  props: {
    endereco: {
      required: true,
    },
    cliente: {
      required: true,
    },
    blockLenvNovoModal: {
      required: false, default: false
    }
  },
  data () {
    return {
      levantamentoNovoModal: false,
      levantamento: { id_turno: 1 },
      levantamentos: {},
      turnos: [
        { id: 1, nome: "Manhã" },
        { id: 2, nome: "Almoço" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Madrugada" },
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      blockButton: false,
      relatorioUrl: ""
    }
  },
  methods: {
    async addLevantamento () {
      await this.$vs.loading({ container: "#container", scale: 0.6 })
      try {
        const levantamento = await this.$http.post("levantamento", [
          this.levantamento,
          { id_endereco: this.endereco.id },
          { id_cliente: this.cliente.id }
        ])
        //await this.$emit('editEndereco', sendback)
        //await this.getlevantamentosCliente()
        await this.logAssunto(levantamento)
        //this.levantamentoNovoModal = false;
        await this.getLevantamentosEndereco()
        await this.$emit("addLev", levantamento)
        await this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
        await this.$vs.loading.close("#container > .con-vs-loading")
      } finally {
        this.blockButton = await false
        this.levantamento = await {}
        await this.$vs.loading.close("#container > .con-vs-loading")
      }
    },
    // async remove(levant) {
    //   await this.$vs.loading({ container: '#container',scale: 0.6});
    //   let res = {}
    //   try {
    //     res = await this.$http.put("levantamento/"+levant.id, {lixeira: 1});
    //     await this.getLevantamentosEndereco()
    //   } catch (err) {
    //     const error = this.$httpErrors(err);
    //     this.$vs.notify(error);
    //   } finally {
    //     await this.$emit("removLev", res);
    //     await this.$vs.loading.close('#container > .con-vs-loading');
    //   }
    // },
    async getLevantamentosEndereco () {
      await this.$vs.loading({ container: "#container", scale: 0.6 })
      try {
        const res = await this.$http.get(
          "LevantamentosEndereco/" + this.endereco.id
        )
        this.levantamentos = res.levantamentos
        this.relatorioUrl = res.relatorioUrl
        await this.errors.clear()
        await this.$vs.loading.close("#container > .con-vs-loading")
      } catch (err) {
        await this.$vs.loading.close("#container > .con-vs-loading")
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async logAssunto (res) {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro do levantamento N°" + res.id,
        // }
        // await this.$logger(logData)
        await this.$http.post("assuntoCliente", {
          id_assuntos: 3,
          id_cliente: res.id_cliente,
          id_contrato: res.id_contrato,
          id_estagio: 1,
          status: 1,
          tipo: this.cliente.id_tipo_contato,
          id_colaborador: window.localStorage.getItem("id"),
          obs: "Cadastro do levantamento N°" + res.id
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async redirectBlank (os) {
      const link = `${this.relatorioUrl}/?os=${os.id}&lev=1`
      var a = document.createElement('a')
      a.target = "_blank"
      a.href = link
      a.click()
    },
    async submitForm () {
      this.blockButton = true
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            if (this.levantamento.id_turno || this.levantamento.hora_marcada) {
              this.addLevantamento()
            } else {
              this.$vs.notify({
                title: "Erro",
                text: "Escolha o Turno ou Hora Marcada",
                color: "danger",
              })
              this.blockButton = false
            }
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            })
            this.blockButton = false
          }
        })
      })
    },
  },
  async mounted () {
    await this.getLevantamentosEndereco()
  },
  components: { ...components, LevNaoExec },
};
</script>

<style lang="scss" scoped>
.card-lev {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-levantamento-novo-modal {
  height: 55vh !important;
}
.sizeModal {
  height: 60vh !important;
}
</style>
