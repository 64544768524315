<template>
  <div style="overflow: auto" class="w-full scroll-general-documentos">
    <!-- <VuePerfectScrollbar class="scroll-general-documentos" :settings="settings"> -->
    <div v-if="documentos.length > 0">
      <vx-card>
        <VuePerfectScrollbar class="scroll-documentos" :settings="settings">
          <div class="w-full">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <vs-col
                v-for="data in documentos"
                :key="data.id"
                vs-w="6"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <vx-card
                  :class="cardClass"
                  class="mx-1 my-3"
                  card-border
                  no-shadow
                >
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <a target="_blank" :href="'/contrato?id=' + data.id">
                            <vs-row
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  <h6
                                    class="font-semibold"
                                    style="color: black"
                                  >
                                    {{ data.estagio }} Nº.{{ data.id }} -
                                    {{
                                      data.renovacao > 0 &&
                                      data.id_relacional > 0
                                        ? "Renovação"
                                        : data.id_status == 3
                                        ? "Cancelado"
                                        : "Novo"
                                    }}
                                  </h6>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span
                                  class="item-description text-sm"
                                  style="font-size: 17px !important"
                                >
                                  <span
                                    class="font-semibold"
                                    v-if="data.id_estagio > 1"
                                  >
                                    <span style="color: black">
                                      Data:
                                      {{
                                        data.id_estagio == 2
                                          ? data.data_proposta
                                          : data.data_contrato
                                      }}
                                    </span>
                                  </span>
                                </span>
                              </vs-col>
                            </vs-row>
                          </a>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
                <!-- -->
              </vs-col>
            </vs-row>
          </div>
        </VuePerfectScrollbar>
      </vx-card>
      <vx-card class="my-2">
        <vs-divider border-style="dashed" color="blue" class="pt-5 my-0">
          <span style="font-size: 17px; font-weight: 700">Links</span>
        </vs-divider>
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <vs-col
            vs-w="6"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-card
              :class="cardClass"
              class="mx-1 my-3"
              @click="verContas()"
              card-border
              no-shadow
              card-background="warning"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <h4 style="color: white">Contas</h4>
              </vs-col>
            </vx-card>
          </vs-col>
          <vs-col
            vs-w="6"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-card
              :class="cardClass"
              class="mx-1 my-3"
              @click="verNotas()"
              card-border
              no-shadow
              card-background="warning"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <h4 style="color: white">Notas</h4>
              </vs-col>
            </vx-card>
          </vs-col>
        </vs-row>
      </vx-card>
      <vx-card
        no-radius
        title="Pós-Venda"
        class="p-0 m-0"
        v-if="posVendas.length > 0"
      >
        <div class="w-full">
          <vs-row
            vs-w="12"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vs-col
              v-for="item in posVendas"
              :key="item.id"
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <vx-card
                no-shadow
                no-radius
                :title="item.created_at"
                class="collapsed p-0 m-0"
                card-background="white"
                style="font-size: 12px"
                collapseAction
                data-action="collapse"
              >
                <div class="w-full">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      v-for="(pergunta, index) in perguntas"
                      :key="index"
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vx-card class="mx-1 my-1" card-border no-shadow>
                          <template slot="no-body">
                            <div class="item-details px-2">
                              <div class="my-1 w-full">
                                <span class="item-description text-sm">{{
                                  pergunta.texto
                                }}</span>
                                <span
                                  class="text-sm font-bold"
                                  style="color: black"
                                >
                                  {{
                                    pergunta[`label${item[`resp${index + 1}`]}`]
                                  }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </vx-card>
                      </div>
                    </vs-col>
                  </vs-row>
                  <div class="w-full">
                    <vs-row
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full">
                          <div class="item-details px-2">
                            <div
                              class="my-1 w-full"
                              style="text-align: justify"
                            >
                              <b>Observação:</b>
                              <span class="item-description text-sm">{{
                                item.obs
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vx-card>
    </div>
    <div v-else>
      <vs-col
        class="my-10"
        vs-w="12"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
      >
        <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
      </vs-col>
    </div>
    <!-- </VuePerfectScrollbar> -->
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    item: {
      require: true
    }
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      },
      posVendas: [],
      perguntas: [],
      documentos: [],
      cardClass: "hover-card overflow-hidden"
    }
  },
  methods: {
    async getPosVendaCliente () {
      try {
        const res = await this.$http.get(`getPosVendaCliente/` + this.item.id)
        this.posVendas = res.respostas
        this.perguntas = res.perguntas
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getDocumentos () {
      try {
        this.documentos = await this.$http.get(
          `getDocumentosByCliente/` + this.item.id
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async verContrato (id) {
      const link = `/contrato?id=${id}`
      var a = document.createElement("a")
      // a.target = "_blank"
      a.href = link
      a.click()
    },
    async verNotas () {
      const link = `/nota?cliente=${this.item.id}`
      var a = document.createElement("a")
      a.target = "_blank"
      a.href = link
      a.click()
    },
    async verContas () {
      const link = `/contas-receber?cliente=${this.item.id}`
      var a = document.createElement("a")
      a.target = "_blank"
      a.href = link
      a.click()
    }
  },
  async mounted () {
    await this.$vs.loading()
    await this.getDocumentos()
    await this.getPosVendaCliente()
    await this.$vs.loading.close()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss" scoped>
.hover-card {
  transition-duration: 60ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-general-documentos {
  max-height: 70vh !important;
}
.scroll-documentos {
  max-height: 40vh !important;
}
</style>
