<template>
  <div>
    <VuePerfectScrollbar class="scroll-add-contato" :settings="settings">
      <div id="listaContato" class="w-full px-2 vs-con-loading__container">
        <div v-if="contatos.length > 0" style="overflow: hidden">
          <vx-card
            no-radius
            no-shadow
            title="Contatos Cadastrados"
            class="collapsed p-0 m-0"
            card-background="white"
            collapseAction
            data-action="collapse"
          >
            <div class="vx-row">
              <div class="w-full px-3">
                <vs-list>
                  <span :key="indextr" v-for="(tr, indextr) in contatos">
                    <vx-card
                      class="card-lev overflow-hidden my-2"
                      :card-background="tr.lixeira == 1 ? 'danger' : ''"
                    >
                      <template slot="no-body">
                        <div class="item-details px-2">
                          <div class="my-2">
                            <vs-col
                              vs-w="0.5"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              class=""
                            >
                              <span v-if="tr.tipo == 1">
                                <vs-icon
                                  icon="alternate_email"
                                  size="25px"
                                  color="dark"
                                ></vs-icon>
                              </span>
                              <span v-else>
                                <vs-icon
                                  icon="phone"
                                  size="25px"
                                  color="dark"
                                ></vs-icon>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="8"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              class=""
                              :style="
                                tr.lixeira == 1
                                  ? 'color: white'
                                  : 'color: black'
                              "
                            >
                              <div class="w-full">
                                <span style="font-size: 14px">{{
                                  tr.nome
                                }}</span>
                                <span style="font-size: 14px">
                                  &nbsp;- <b>{{ tr.contato }}</b></span
                                >
                              </div>
                            </vs-col>
                            <vs-col
                              vs-w="1.5"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                              class=""
                              :style="
                                tr.lixeira == 1
                                  ? 'color: white'
                                  : 'color: black'
                              "
                            >
                              <span style="font-size: 12px"
                                ><b>{{ tr.setor }}</b></span
                              >
                            </vs-col>
                            <vs-col
                              vs-w="2"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="center"
                            >
                              <div
                                @click="atualizar(tr, 1)"
                                v-if="tr.lixeira == 0"
                              >
                                <vs-icon
                                  style="cursor: pointer"
                                  icon="highlight_off"
                                  size="25px"
                                  color="danger"
                                ></vs-icon>
                              </div>
                              <div @click="atualizar(tr, 0)" v-else>
                                <vs-icon
                                  style="cursor: pointer"
                                  icon="undo"
                                  size="25px"
                                  color="warning"
                                ></vs-icon>
                              </div>
                            </vs-col>
                          </div>
                        </div>
                      </template>
                    </vx-card>
                  </span>
                </vs-list>
              </div>
            </div>
          </vx-card>
        </div>
      </div>

      <div class="px-2" v-if="edit != false">
        <vx-card
          no-radius
          no-shadow
          title="Cadastrar Contato"
          class="collapsed p-0 m-0"
          card-background="white"
          collapseAction
          data-action="collapse"
        >
          <div>
            <vs-row vs-type="flex">
              <vs-col
                vs-w="3.8"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                class=""
              >
                <div style="display: inline-block" class="w-full">
                  <vs-input
                    label="Nome"
                    v-validate="'required'"
                    name="nome"
                    v-model="contato.nome"
                    class="w-full px-1"
                  />
                  <span
                    class="ml-3 text-danger"
                    style="font-size: 11px"
                    v-show="errors.has('nome')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="3.8"
                class="mx-2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              >
                <div style="display: inline-block; margin-right: 20px">
                  <label style="color: black; font-size: 13px">Setor</label
                  ><br />
                  <el-select
                    filterable
                    clearable
                    class="select-info"
                    placeholder="Selecione..."
                    name="setor"
                    v-validate="'required'"
                    v-model="contato.id_setor"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="setor in setores"
                      class="select-info"
                      :value="setor.id"
                      :label="setor.nome"
                      :key="setor.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="text-danger"
                    style="font-size: 11px"
                    v-show="errors.has('setor')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="3.2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
                class=""
              >
                <b-form-group>
                  <b-form-radio-group
                    id="tipo"
                    v-model="contato.tipo"
                    :options="tipoContato"
                    size="lg"
                    buttons
                    @change="reRender += 1"
                    button-variant=""
                    name="tipo"
                    class=""
                  ></b-form-radio-group>
                </b-form-group>
              </vs-col>
            </vs-row>
            <vs-row vs-type="flex">
              <vs-col
                vs-w="8"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                class=""
              >
                <div style="display: inline-block" class="w-full">
                  <vs-input
                    label="Contato"
                    name="contato"
                    :disabled="!contato.tipo"
                    v-model="contato.contato"
                    :key="reRender"
                    v-validate="'required'"
                    v-mask="contato.tipo == 2 ? '(##)#####-####' : mascara"
                    class="inputx w-full px-1"
                  />
                  <span
                    class="ml-3 text-danger"
                    style="font-size: 11px"
                    v-show="errors.has('contato')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                class="mt-6"
              >
                <SuccessButton
                  size=""
                  :disabled="
                    (!$atividades.permissoes.includes(2) &&
                      !$atividades.permissoes.includes(5) &&
                      !$atividades.permissoes.includes(8) &&
                      !$atividades.permissoes.includes(14)) ||
                    blockButton
                  "
                  class=""
                  text="Adicionar"
                  @func="submitForm"
                ></SuccessButton>
              </vs-col>
            </vs-row>
          </div>
        </vx-card>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    cliente: {
      required: true,
    },
    edit: true,
  },
  data() {
    return {
      contato: {},
      contatos: {},
      setores: {},
      tipoContato: [
        { value: 1, text: "EMAIL" },
        { value: 2, text: "TELEFONE" },
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      mascara: "X".repeat(255),
      blockButton: false,
      reRender: 0,
    };
  },
  methods: {
    async addcontato() {
      this.contato.id_cliente = this.cliente.id;
      await this.$vs.loading({ container: "#listaContato", scale: 0.6 });
      try {
        await this.$http.post("contato", this.contato);
        await this.$vs.loading.close();
        await this.getContatos();
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.contato = {};
        this.blockButton = false;
        this.reRender += 1;
        await this.$vs.loading.close("#listaContato > .con-vs-loading");
      }
    },
    async atualizar(contato, status) {
      await this.$vs.loading({ container: "#listaContato", scale: 0.6 });
      try {
        await this.$http.put("mudarStatusContatoCliente", { id: contato.id, lixeira: status });
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "delete",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: `alterou o status do contato N° ${contato.id}`,
        // };
        // await this.$logger(logData);
        await this.getContatos();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#listaContato > .con-vs-loading");
      }
    },
    async getSetores() {
      try {
        this.setores = await this.$http.get("setor");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContatos() {
      await this.$vs.loading({ container: "#listaContato", scale: 0.6 });
      try {
        this.contatos = await this.$http.get(
          "contatoCliente/" + this.cliente.id
        );
        await this.$vs.loading.close("#listaContato > .con-vs-loading");
      } catch (err) {
        await this.$vs.loading.close("#listaContato > .con-vs-loading");
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async submitForm() {
      this.blockButton = true;
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.addcontato();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            });
            this.blockButton = false;
          }
        });
      });
    },
  },
  async mounted() {
    await this.getContatos();
    await this.getSetores();
  },
  components: { ...components },
};
</script>

<style lang="scss" scoped>
.card-lev {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-add-contato {
  height: 60vh !important;
}
</style>
