<template>
  <vs-col
    vs-w="12"
    class="px-2 tooltip-ajust"
    vs-type="flex"
    vs-justify="center"
    vs-align="center"
    id="tooltip-select"
  >
    <vx-tooltip color="success" text="Ligação Ativa">
      <vs-button
        icon="add_ic_call"
        color="success"
        radius
        size="large"
        class="m-2"
        :type="ligAtivButton"
        @click="selectInteraction(1)"
      ></vs-button>
    </vx-tooltip>
    <vx-tooltip color="warning" text="Ligação Receptiva">
      <vs-button
        icon="phone_callback"
        color="warning"
        radius
        size="large"
        class="m-2"
        :type="ligRecepButton"
        @click="selectInteraction(2)"
      ></vs-button>
    </vx-tooltip>
    <vx-tooltip color="blue" text="Abordagem Ativa">
      <vs-button
        icon="streetview"
        color="blue"
        radius
        size="large"
        class="m-2"
        :type="abordAtivButton"
        @click="selectInteraction(3)"
      ></vs-button>
    </vx-tooltip>
    <vx-tooltip color="danger" text="Abordagem Recept.">
      <vs-button
        icon="drive_eta"
        color="danger"
        radius
        size="large"
        class="m-2"
        :type="abordRecepButton"
        @click="selectInteraction(4)"
      ></vs-button>
    </vx-tooltip>
  </vs-col>
</template>

<script>
export default {
  props: {
    opcao: null,
  },
  data() {
    return {
      ligAtivButton: "flat",
      ligRecepButton: "flat",
      abordAtivButton: "flat",
      abordRecepButton: "flat",
      tooltip: false,
    };
  },
  methods: {
    async selectInteraction(button) {
      if (button == 1) {
        this.ligAtivButton = "border";
        this.ligRecepButton = "flat";
        this.abordAtivButton = "flat";
        this.abordRecepButton = "flat";
      }
      if (button == 2) {
        this.ligAtivButton = "flat";
        this.ligRecepButton = "border";
        this.abordAtivButton = "flat";
        this.abordRecepButton = "flat";
      }
      if (button == 3) {
        this.ligAtivButton = "flat";
        this.ligRecepButton = "flat";
        this.abordAtivButton = "border";
        this.abordRecepButton = "flat";
      }
      if (button == 4) {
        this.ligAtivButton = "flat";
        this.ligRecepButton = "flat";
        this.abordAtivButton = "flat";
        this.abordRecepButton = "border";
      }
      await this.$emit("select-interaction", button);
    },
  },
  async mounted() {
    if (this.opcao) {
      await this.selectInteraction(this.opcao);
    } else {
      await this.selectInteraction(1);
    }
  },
};
</script>
