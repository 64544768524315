<template>
  <form>
    <div :id="'container' + this.cliente.id" class="vs-con-loading__container">
      <VuePerfectScrollbar
        :class="{
          sizeScrollAddress: size == 'small',
          sizeScrollAddressLarge: size == 'large',
        }"
        :settings="settings"
      >
        <div class="px-2" v-if="enderecos.length > 0">
          <vx-card
            no-radius
            no-shadow
            title="Enderecos Cadastrados"
            class="collapsed p-0 m-0"
            card-background="white"
            collapseAction
            data-action="collapse"
          >
            <span :key="indextr" v-for="(tr, indextr) in enderecos">
              <vx-card class="card-endereco overflow-hidden my-2" card-border>
                <template slot="no-body">
                  <div class="item-details px-2">
                    <div class="my-2">
                      <vs-row vs-type="flex" vs-justify="space-between" class>
                        <vs-col
                          vs-w="0.5"
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="center"
                          class
                        >
                          <span>
                            <vs-icon
                              :icon="tr.faturamento ? 'attach_money' : 'place'"
                              size="30px"
                              :color="tr.faturamento ? 'success' : 'primary'"
                            ></vs-icon>
                          </span>
                        </vs-col>
                        <vs-col
                          vs-w="9.5"
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="center"
                        >
                          <span style="font-size: 16px; color: black">
                            {{ tr.nome }}: &nbsp;
                            <b>{{ tr.logradouro }}</b>
                            <span
                              v-if="tr.numero != ''"
                              style="font-size: 16px"
                            >
                              &nbsp;Nº.&nbsp;
                              <b>{{ tr.numero }}</b>
                            </span>
                            &nbsp;
                            <b>, {{ tr.nomeBairro }}</b>
                            &nbsp;|&nbsp;
                            <b>{{ tr.cep }}</b>
                            &nbsp;|&nbsp;
                            <b>{{ tr.nomeCidade }} - {{ tr.nomeEstado }}</b>
                            &nbsp;|&nbsp;
                            <b>{{ tr.nomeSegmento }}</b>
                          </span>
                        </vs-col>
                        <vs-col
                          vs-w="2"
                          vs-type="flex"
                          vs-align="center"
                          vs-justify="flex-end"
                        >
                          <AddContatoEndereco
                            :endereco="tr"
                            class="px-1"
                          ></AddContatoEndereco>
                          <levantamentoNovoModal
                            class="px-1"
                            :blockLenvNovoModal="blockLenvNovoModal"
                            :cliente="cliente"
                            @removLev="removLev"
                            @addLev="addLev"
                            :endereco="tr"
                          ></levantamentoNovoModal>
                          <vs-button
                            class="px-0 py-0 m-0"
                            radius
                            size="medium"
                            color="warning"
                            type="relief"
                            :disabled="!$atividades.permissoes.includes(2)"
                            @click="editEndereco(tr)"
                            icon="edit"
                          ></vs-button>
                        </vs-col>
                      </vs-row>
                    </div>
                  </div>
                </template>
              </vx-card>
            </span>
          </vx-card>
        </div>
        <div class="px-2" v-if="edit != false">
          <vx-card
            no-radius
            no-shadow
            :title="editar ? 'Editar Endereço' : 'Cadastrar Endereço'"
            class="collapsed p-0 m-0"
            card-background="white"
            collapseAction
            data-action="collapse"
          >
            <div>
              <vs-row vs-type="flex">
                <vs-col
                  vs-w="5.8"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-input
                    class="w-full p-3"
                    label="Nome"
                    color="dark"
                    name="nome"
                    v-model="endereco.nome"
                  />
                </vs-col>
                <vs-col
                  vs-w="0.2"
                  vs-type="flex"
                  class="mt-10"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vx-tooltip
                    color="danger"
                    text="Informe um nome para facilitar a identificação no contrato"
                  >
                    <vs-icon
                      style="cursor: pointer"
                      class="w-full px-3"
                      icon="info"
                    ></vs-icon>
                  </vx-tooltip>
                </vs-col>
                <vs-col
                  vs-w="3"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div class="mx-3">
                    <label style="color: black; font-size: 13px"
                      >Segmento</label
                    >
                    <br />
                    <el-select
                      filterable
                      clearable
                      class="select-info"
                      placeholder="Selecione..."
                      v-validate="'required'"
                      name="segmento"
                      v-model="endereco.id_segmento"
                      :popper-append-to-body="pop ? true : false"
                    >
                      <el-option
                        v-for="segmento in segmentos"
                        class="select-info"
                        :value="segmento.id"
                        :label="segmento.nome"
                        :key="segmento.id"
                      ></el-option>
                    </el-select>
                    <vs-row>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('segmento')"
                        >{{ $validators.empty }}</span
                      >
                    </vs-row>
                  </div>
                </vs-col>
                <vs-col
                  vs-w="3"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  class="mt-5"
                >
                  <vs-checkbox
                    class="mx-3"
                    color="success"
                    v-model="endereco.faturamento"
                    >PARA FATURAMENTO</vs-checkbox
                  >
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex">
                <vs-col
                  vs-w="4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div style="display: inline-block" class="w-full">
                    <vs-input
                      color="dark"
                      class="w-full px-3"
                      label="CEP"
                      v-validate="'required'"
                      name="cep"
                      @change="getCepInfo($event)"
                      v-model="endereco.cep"
                    />
                    <span
                      class="ml-3 text-danger text-sm"
                      v-show="errors.has('cep')"
                      >{{ $validators.empty }}</span
                    >
                  </div>
                </vs-col>
                <vs-col
                  vs-w="5"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div style="display: inline-block" class="w-full mx-3">
                    <vs-input
                      class="w-full"
                      label="Logradouro"
                      v-validate="'required'"
                      color="dark"
                      name="logradouro"
                      v-model="endereco.logradouro"
                    />
                    <span
                      class="ml-3 text-danger text-sm"
                      v-show="errors.has('logradouro')"
                      >{{ $validators.empty }}</span
                    >
                  </div>
                </vs-col>
                <vs-col
                  vs-w="3"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div style="display: inline-block" class="w-full">
                    <vs-input
                      color="dark"
                      class="w-full px-3"
                      label="Número"
                      v-validate="'required'"
                      name="numero"
                      v-model="endereco.numero"
                    />
                    <span
                      class="ml-3 text-danger text-sm"
                      v-show="errors.has('numero')"
                      >{{ $validators.empty }}</span
                    >
                  </div>
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex">
                <vs-col
                  vs-w="4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div class="w-full p-3">
                    <label style="color: black; font-size: 13px">Bairro</label>
                    <br />
                    <el-select
                      filterable
                      clearable
                      class="w-full"
                      v-validate="'required'"
                      :disabled="!endereco.id_cidade || !endereco.id_estado"
                      placeholder="Selecione..."
                      is-selected.sync="true"
                      name="bairro"
                      v-model="endereco.id_bairro"
                      :popper-append-to-body="pop ? true : false"
                    >
                      <el-option
                        v-for="bairro in bairros"
                        class="select-info"
                        :value="bairro.id"
                        :label="bairro.nome"
                        :key="bairro.id"
                      ></el-option>
                    </el-select>
                    <vs-row>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('bairro')"
                        >{{ $validators.empty }}</span
                      >
                    </vs-row>
                  </div>
                </vs-col>
                <vs-col
                  vs-w="4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div class="w-full p-3">
                    <label style="color: black; font-size: 13px">Cidade</label>
                    <br />
                    <el-select
                      filterable
                      clearable
                      class="w-full"
                      :disabled="!endereco.id_estado"
                      @change="getBairro($event)"
                      v-validate="'required'"
                      placeholder="Selecione..."
                      is-selected.sync="true"
                      name="id_cidade"
                      v-model="endereco.id_cidade"
                      :popper-append-to-body="pop ? true : false"
                    >
                      <el-option
                        v-for="cidade in cidades"
                        class="select-info"
                        :value="cidade.id"
                        :label="cidade.nome"
                        :key="cidade.id"
                      ></el-option>
                    </el-select>
                    <vs-row>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('id_cidade')"
                        >{{ $validators.empty }}</span
                      >
                    </vs-row>
                  </div>
                </vs-col>
                <vs-col
                  vs-w="4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div class="w-full p-3">
                    <label style="color: black; font-size: 13px">Estado</label>
                    <br />
                    <el-select
                      filterable
                      clearable
                      class="w-full"
                      v-validate="'required'"
                      @change="getCidade($event)"
                      placeholder="Selecione..."
                      is-selected.sync="true"
                      name="estado"
                      v-model="endereco.id_estado"
                      :popper-append-to-body="pop ? true : false"
                    >
                      <el-option
                        v-for="estado in estados"
                        class="select-info"
                        :value="estado.id"
                        :label="estado.nome"
                        :key="estado.id"
                      ></el-option>
                    </el-select>
                    <vs-row>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('estado')"
                        >{{ $validators.empty }}</span
                      >
                    </vs-row>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex">
                <vs-col
                  vs-w="12"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-input
                    class="w-full mx-3"
                    label="Complemento"
                    color="dark"
                    name="complemento"
                    v-model="endereco.complemento"
                  />
                </vs-col>
              </vs-row>
              <vs-col
                v-if="!editar"
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                class="mt-2"
              >
                <vs-button
                  class="w-full mt-2 mx-2"
                  color="success"
                  type="relief"
                  icon="add"
                  :disabled="!$atividades.permissoes.includes(2)"
                  @click="submitForm()"
                  >Adicionar</vs-button
                >
              </vs-col>
              <vs-col
                v-if="editar"
                vs-w="9"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                class="my-2"
              >
                <vs-button
                  class="w-full mt-2 mx-2"
                  color="warning"
                  type="relief"
                  icon="edit"
                  @click="submitForm()"
                  >Editar</vs-button
                >
              </vs-col>
              <vs-col
                v-if="editar"
                vs-w="3"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                class="my-2"
              >
                <vs-button
                  class="w-full mt-2 mx-2"
                  color="danger"
                  icon="clear"
                  type="relief"
                  @click="clear()"
                  >Cancelar</vs-button
                >
              </vs-col>
            </div>
          </vx-card>
        </div>
      </VuePerfectScrollbar>
    </div>
  </form>
</template>

<script>
import components from "@/components/default/exports.js"
import levantamentoNovoModal from "./../../kanban/components/levantamentoNovoModal.vue"
import AddContatoEndereco from "./AddContatoEndereco.vue"

export default {
  props: {
    cliente: { required: true },
    blockLenvNovoModal: { default: false },
    pop: false,
    size: {},
    edit: true
  },
  data () {
    return {
      endereco: {
        logradouro: "",
        complemento: "",
        id_estado: null,
        id_cidade: null,
        id_bairro: null,
        id_segmento: null,
        nome: "",
        numero: "",
        cep: ""
      },
      enderecos: [],
      levantamentos: [],
      cidades: [],
      estados: [],
      bairros: [],
      segmentos: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      },
      editar: false
    }
  },
  methods: {
    async addEndereco () {
      let sendback
      await this.$vs.loading({
        container: "#container" + this.cliente.id,
        scale: 0.6
      })
      if (this.endereco.nome == "" || this.endereco.nome == null) {
        this.endereco.nome = this.endereco.logradouro
      }
      try {
        if (this.editar) {
          const res = await this.$http.put("endereco/" + this.endereco.id, [
            this.endereco,
            { id_cliente: this.cliente.id}
          ])
          // let logData = {
          //   id_colaborador: localStorage.getItem("id"),
          //   funcao: "atualizar",
          //   setor: "comercial",
          //   ip: window.localStorage.getItem("ip"),
          //   texto: "Editação do Endereço N°" + res.id
          // }
          // await this.$logger(logData)
          this.editar = await false
        } else {
          const res = await this.$http.post("endereco", [
            this.endereco,
            { id_cliente: this.cliente.id }
          ])
          // let logData = {
          //   id_colaborador: localStorage.getItem("id"),
          //   funcao: "atualizar",
          //   setor: "comercial",
          //   ip: window.localStorage.getItem("ip"),
          //   texto: "Cadastro do Endereço N°" + res.id
          // }
          // await this.$logger(logData)
          sendback = this.endereco
          await this.$emit("editEndereco", sendback)
        }
        this.endereco = await {
          id_bairro: null,
          id_cidade: null,
          id_estado: null,
          id_segmento: null
        }
        await this.getEnderecosCliente()
        await this.$vs.loading.close(
          "#container" + this.cliente.id + " > .con-vs-loading"
        )
        await this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
        await this.$vs.loading.close(
          "#container" + this.cliente.id + " > .con-vs-loading"
        )
      }
    },
    async editEndereco (endereco) {
      this.errors.clear()
      this.endereco.id = endereco.id
      this.endereco.id_estado = endereco.id_estado
      this.endereco.id_cidade = endereco.id_cidade
      this.endereco.id_bairro = endereco.id_bairro
      this.endereco.id_segmento = endereco.id_segmento
      this.endereco.faturamento = endereco.faturamento
      this.endereco.cep = endereco.cep
      this.endereco.numero = endereco.numero
      this.endereco.logradouro = endereco.logradouro
      this.endereco.complemento = endereco.complemento
      this.endereco.nome = endereco.nome
      this.editar = true
      this.getCidade(this.endereco.id_estado)
      this.getBairro(this.endereco.id_cidade)
    },
    async clear () {
      this.endereco = await {
        id_bairro: null,
        id: null,
        id_cidade: null,
        id_estado: null,
        id_segmento: null
      }
      this.editar = await false
      await this.getEnderecosCliente()
    },
    async addLev (levantamento) {
      await this.$emit("addLev", levantamento)
    },
    async removLev (levantamento) {
      await this.$emit("removLev", levantamento)
    },
    async getCidade (estado) {
      // await this.$vs.loading({
      //   container: "#container" + this.cliente.id,
      //   scale: 0.6,
      // });
      try {
        this.cidades = await this.$http.get("cidade/" + estado)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        // await this.$vs.loading.close(
        //   "#container" + this.cliente.id + " > .con-vs-loading"
        // );
      }
    },
    async getBairro (cidade) {
      await this.$vs.loading({
        container: "#container" + this.cliente.id,
        scale: 0.6
      })
      try {
        this.bairros = await this.$http.get("bairro/" + cidade)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#container" + this.cliente.id + " > .con-vs-loading"
        )
      }
    },
    async getEstado () {
      //await this.$vs.loading({ container: '#container',scale: 0.6});
      try {
        this.estados = await this.$http.get("estado")
        // await this.getCidade(this.endereco.id_estado);
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        //await this.$vs.loading.close('#container > .con-vs-loading');
      }
    },
    async getSegmentos () {
      //await this.$vs.loading({ container: '#container',scale: 0.6});
      try {
        this.segmentos = await this.$http.get("segmento")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        //await this.$vs.loading.close('#container > .con-vs-loading');
      }
    },
    async getCepInfo ($event) {
      await this.$vs.loading({
        container: "#container" + this.cliente.id,
        scale: 0.6
      })
      try {
        const res = await this.$http.post(`cep`, { cep: $event.target.value })
        this.endereco.logradouro = await res.cep.street
        this.endereco.id_estado = await res.estado.id
        this.endereco.id_cidade = await res.cidade.id
        this.endereco.id_bairro = await res.bairro.id
        this.getCidade(this.endereco.id_estado)
        this.getBairro(this.endereco.id_cidade)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#container" + this.cliente.id + " > .con-vs-loading"
        )
      }
    },
    async getEnderecosCliente () {
      await this.$vs.loading({
        container: "#container" + this.cliente.id,
        scale: 0.6
      })
      try {
        this.enderecos = await this.$http.get(
          "enderecosCliente/" + this.cliente.id
        )
        this.errors.clear()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#container" + this.cliente.id + " > .con-vs-loading"
        )
      }
    },
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(async result => {
          if (result) {
            this.addEndereco()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
          }
        })
      })
    }
  },
  async mounted () {
    await this.getEstado()
    await this.getSegmentos()
    await this.getEnderecosCliente()
  },
  components: { ...components, levantamentoNovoModal, AddContatoEndereco }
};
</script>
<style lang="scss">
.sizeScrollAddress {
  max-height: 280px !important;
}
.sizeScrollAddressLarge {
  max-height: 60vh !important;
}
.vx-card__title h4 {
  font-weight: 600;
  text-transform: uppercase !important;
}
.feather-chevron-up {
  width: 30px !important;
  height: 30px !important;
}
.card-endereco {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
